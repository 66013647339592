<template>
  <div class="card-form flex flex-col">
    <span
      class="bg-gray-400 font-bold leading-none ml-2 sm:ml-8 pl-4 pt-3 px-8 py-2 rounded-t-lg text-left w-10/12 sm:w-2/5"
    >
      <slot name="title" />
    </span>
    <div class="border border-gray-400 rounded-lg shadow-lg">
      <slot name="form" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardFormEmpty',
};
</script>

<style>
.card-form + .card-form {
  margin-top: 3rem;
}
</style>
