var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "card-form-empty",
        [
          _c("template", { slot: "title" }, [_vm._v("IP Control")]),
          _c("template", { staticClass: "flex", slot: "form" }, [
            _c("div", { staticClass: "flex flex-col w-full lg:flex-row" }, [
              _c(
                "div",
                { staticClass: "w-full lg:w-1/2 border-r border-gray-400" },
                [
                  _c("h3", { staticClass: "card-subtitle card-subtitle-l" }, [
                    _vm._v("Delivery (Impressions/clicks)"),
                  ]),
                  _c("div", { staticClass: "card-content" }, [
                    _vm.fraud.ipControl.repeatedIps
                      ? _c(
                          "div",
                          { staticClass: "flex w-full" },
                          [
                            _c(
                              "sun-label-group",
                              {
                                staticClass: "w-full mt-2",
                                attrs: {
                                  text: "Block Conversions for repeated IPs",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex mt-3 mx-2" },
                                  [
                                    _c("sun-toggle", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        name: "repetead-ips-toggle",
                                        disabled: _vm.isDisabled,
                                      },
                                      on: { change: _vm.onUpdateFraud },
                                      model: {
                                        value:
                                          _vm.fraud.ipControl.blockRepeatedIps,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.fraud.ipControl,
                                            "blockRepeatedIps",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "fraud.ipControl.blockRepeatedIps",
                                      },
                                    }),
                                    _c(
                                      "p",
                                      { staticClass: "group-description" },
                                      [_vm._v(_vm._s(_vm.blockRepeatedIpsText))]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "w-full h-0 lg:h-16" }),
                    _c(
                      "div",
                      { staticClass: "flex flex-col w-full mt-6" },
                      [
                        _c(
                          "sun-label-group",
                          {
                            staticClass: "mt-2 w-full",
                            attrs: { text: "Usage type IP" },
                          },
                          [
                            _c("sun-select", {
                              attrs: {
                                options: _vm.ipUsageTypes,
                                "track-by": "id",
                                label: "name",
                                required: "required",
                                "text-error": "This field is required.",
                                "close-on-select": "",
                                disabled: _vm.isDisabled,
                              },
                              on: { change: _vm.onUpdateFraud },
                              model: {
                                value: _vm.fraud.usageType.click,
                                callback: function ($$v) {
                                  _vm.$set(_vm.fraud.usageType, "click", $$v)
                                },
                                expression: "fraud.usageType.click",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.fraud.usageType.click
                          ? _c("p", { staticClass: "group-description" }, [
                              _vm._v(_vm._s(_vm.usageTypeDescription("click"))),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex w-full mt-6" },
                      [
                        _c("include-input", {
                          staticClass: "w-full",
                          attrs: {
                            "allow-exclude": "",
                            "include-id": "asnFilter",
                            title: "ASN",
                            paginated: true,
                            disabled: _vm.isDisabled,
                          },
                          on: { input: _vm.changeAsnIncludes },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function () {
                                return [_vm._v(" + add ASN include ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value-text",
                              fn: function ({ item }) {
                                return [_vm._v(_vm._s(item.name))]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.fraud.asn.asnFilter,
                            callback: function ($$v) {
                              _vm.$set(_vm.fraud.asn, "asnFilter", $$v)
                            },
                            expression: "fraud.asn.asnFilter",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c("div", { staticClass: "w-full lg:w-1/2" }, [
                _c("h3", { staticClass: "card-subtitle card-subtitle-r" }, [
                  _vm._v("Adserver Core (Leads/PostEvents)"),
                ]),
                _c("div", { staticClass: "card-content" }, [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "sun-label-group",
                        {
                          staticClass: "mt-2 w-1/2 pr-5",
                          attrs: {
                            text: "Retain Conversions for repeated IPs",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex mt-3 mx-2" },
                            [
                              _c("sun-toggle", {
                                staticClass: "mr-2",
                                attrs: {
                                  name: "repetead-ips-toggle",
                                  disabled: _vm.isDisabled,
                                },
                                on: { change: _vm.changeIpControl },
                                model: {
                                  value: _vm.fraud.ipControl.repeatedIps,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.fraud.ipControl,
                                      "repeatedIps",
                                      $$v
                                    )
                                  },
                                  expression: "fraud.ipControl.repeatedIps",
                                },
                              }),
                              _c("p", { staticClass: "group-description" }, [
                                _vm._v(
                                  _vm._s(_vm.retainConversionsRepeatedText)
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                      !!_vm.fraud.ipControl.repeatedIps
                        ? _c(
                            "sun-label-group",
                            {
                              staticClass: "mt-2 w-1/2",
                              attrs: { text: "Max leads per Day per IP" },
                            },
                            [
                              _c("asterix-input", {
                                ref: "ips",
                                attrs: {
                                  id: "max-leads",
                                  type: "number",
                                  name: "max-leads",
                                  "validate-on-blur": "",
                                  min: 0,
                                  max: 10,
                                  disabled:
                                    !_vm.fraud.ipControl.repeatedIps ||
                                    _vm.isDisabled,
                                },
                                on: { change: _vm.onUpdateFraud },
                                model: {
                                  value: _vm.fraud.ipControl.maxLeadsPerIP,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.fraud.ipControl,
                                      "maxLeadsPerIP",
                                      $$v
                                    )
                                  },
                                  expression: "fraud.ipControl.maxLeadsPerIP",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex mt-6" }, [
                    _c(
                      "div",
                      { staticClass: "flex flex-col w-full" },
                      [
                        _c(
                          "sun-label-group",
                          {
                            staticClass: "mt-2 w-full pr-5",
                            attrs: { text: "Usage Type Click IP" },
                          },
                          [
                            _c("sun-select", {
                              attrs: {
                                options: _vm.ipUsageTypes,
                                "track-by": "id",
                                label: "name",
                                required: "required",
                                "text-error": "This field is required.",
                                "close-on-select": "",
                                disabled: _vm.isDisabled,
                              },
                              on: { change: _vm.onUpdateFraud },
                              model: {
                                value: _vm.fraud.usageType.lead_click,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.fraud.usageType,
                                    "lead_click",
                                    $$v
                                  )
                                },
                                expression: "fraud.usageType.lead_click",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.fraud.usageType.lead_click
                          ? _c("p", { staticClass: "group-description" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.usageTypeDescription("lead_click")
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex flex-col w-full" },
                      [
                        _c(
                          "sun-label-group",
                          {
                            staticClass: "mt-2 w-full",
                            attrs: { text: "Usage Type Notification IP" },
                          },
                          [
                            _c("sun-select", {
                              attrs: {
                                options: _vm.ipUsageTypes,
                                "track-by": "id",
                                label: "name",
                                required: "required",
                                "text-error": "This field is required.",
                                "close-on-select": "",
                                disabled: _vm.isDisabled,
                              },
                              on: { change: _vm.onUpdateFraud },
                              model: {
                                value: _vm.fraud.usageType.lead_conversion,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.fraud.usageType,
                                    "lead_conversion",
                                    $$v
                                  )
                                },
                                expression: "fraud.usageType.lead_conversion",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.fraud.usageType.lead_conversion
                          ? _c("p", { staticClass: "group-description" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.usageTypeDescription("lead_conversion")
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex mt-6" },
                    [
                      _c(
                        "sun-label-group",
                        {
                          staticClass: "mt-2 w-full pr-5",
                          attrs: { text: "Secondary IP Click" },
                        },
                        [
                          _c("sun-select", {
                            attrs: {
                              options: _vm.proxyUsageTypeOptionsList,
                              "track-by": "id",
                              label: "name",
                              required: "required",
                              "text-error": "This field is required.",
                              "close-on-select": "",
                              disabled: _vm.isDisabled,
                            },
                            on: { change: _vm.onUpdateFraud },
                            model: {
                              value: _vm.fraud.proxyUsageType.leadClick,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fraud.proxyUsageType,
                                  "leadClick",
                                  $$v
                                )
                              },
                              expression: "fraud.proxyUsageType.leadClick",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "sun-label-group",
                        {
                          staticClass: "mt-2 w-full",
                          attrs: { text: "Secondary IP Notification" },
                        },
                        [
                          _c("sun-select", {
                            attrs: {
                              options: _vm.proxyUsageTypeOptionsList,
                              "track-by": "id",
                              label: "name",
                              required: "required",
                              "text-error": "This field is required.",
                              "close-on-select": "",
                              disabled: _vm.isDisabled,
                            },
                            on: { change: _vm.onUpdateFraud },
                            model: {
                              value: _vm.fraud.proxyUsageType.leadConversion,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fraud.proxyUsageType,
                                  "leadConversion",
                                  $$v
                                )
                              },
                              expression: "fraud.proxyUsageType.leadConversion",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex flex-col mt-6" },
                    [
                      _c(
                        "sun-label-group",
                        {
                          staticClass: "mt-2 w-full",
                          attrs: { text: "IP Missmatch" },
                        },
                        [
                          _c("sun-select", {
                            attrs: {
                              options: _vm.ipMissMatchOptionsList,
                              "track-by": "id",
                              label: "name",
                              "text-error": "This field is required.",
                              "close-on-select": "",
                              disabled: _vm.isDisabled,
                            },
                            on: { change: _vm.onUpdateFraud },
                            model: {
                              value: _vm.fraud.ipMissMatch.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.fraud.ipMissMatch, "value", $$v)
                              },
                              expression: "fraud.ipMissMatch.value",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.fraud.ipMissMatch.value
                        ? _c("p", { staticClass: "group-description" }, [
                            _vm._v(" " + _vm._s(_vm.ipMissMatchText) + " "),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }