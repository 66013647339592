<template>
  <div>
    <card-form>
      <template slot="title">Referer</template>
      <template slot="form">
        <form-row>
          <template #left>
            <sun-label-group>
              <div class="flex">
                <sun-toggle
                  v-model="fraud.referer.traficIDFA"
                  class="mr-2"
                  name="only-traffic-with-IDFA-toggle"
                  :disabled="isDisabled"
                  @change="onUpdateFraud"
                />
                <p>Only traffic with IDFA/GAID</p>
              </div>
            </sun-label-group>
          </template>
          <template #right>
            <sun-label-group>
              <sun-toggle
                v-model="fraud.referer.traficPublisher"
                class="mr-2"
                name="only-traffic-with-referer-toggle"
                :disabled="isDisabled"
                @change="onUpdateFraud"
              />
              <p>Only traffic with Referer</p>
            </sun-label-group>
          </template>
        </form-row>
        <form-row>
          <template #left>
            <sun-label-group>
              <div class="flex">
                <sun-toggle
                  v-model="fraud.referer.traficDomainBundle"
                  class="mr-2"
                  name="only-traffic-with-domain-toggle"
                  :disabled="isDisabled"
                  @change="onUpdateFraud"
                />
                <p>Only traffic with domain/bundle</p>
              </div>
            </sun-label-group>
          </template>
          <template #right>
            <sun-label-group>
              <sun-toggle
                v-model="fraud.referer.blockTraficApp"
                class="mr-2"
                name="block-traffic-toggle"
                :disabled="isDisabled"
                @change="onUpdateFraud"
              />
              <p>Block inapp traffic</p>
            </sun-label-group>
          </template>
        </form-row>
        <form-row>
          <div class="w-full lg:pr-4">
            <include-input
              v-model="fraud.referer.sourcesFilter"
              include-id="sourcesFilter"
              title="Sources"
              :disabled="isDisabled"
              @input="changeRefererIncludes"
            >
              <template #default> + add sources include </template>
              <template #value-text="{ item }">{{ item.name }}</template>
            </include-input>
          </div>
        </form-row>
      </template>
    </card-form>
  </div>
</template>
<script>
import { fraudFormMixin } from '@/mixins/modules/ecommerce/fraudFormMixin';
import { deepClone } from '@/utils/deepClone';
import CardForm from '@/components/atoms/CardForm';
import IncludeInput from '@/components/organisms/shared/includes/IncludeInput';
import FormRow from '@/components/atoms/FormRow/FormRow';

export default {
  name: 'Referer',
  components: { CardForm, IncludeInput, FormRow },
  mixins: [fraudFormMixin],
  props: {
    baseFraud: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fraud: null,
    };
  },
  watch: {
    baseFraud: {
      deep: true,
      handler(updatedFraud) {
        this.fraud = deepClone(updatedFraud);
      },
    },
  },
  created() {
    this.fraud = deepClone(this.baseFraud);
  },
};
</script>
