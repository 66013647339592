var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass: "flex flex-col items-end",
      class: _vm.data.staticClass || "",
    },
    [
      _c("div", {
        staticClass: "w-2/12 h-6 bg-gray-300 animate-pulse rounded-xl mb-4",
      }),
      _c("div", {
        staticClass: "w-full h-10 bg-gray-300 animate-pulse rounded",
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }