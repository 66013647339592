<template functional>
  <div class="flex flex-col items-end" :class="data.staticClass || ''">
    <div class="w-2/12 h-6 bg-gray-300 animate-pulse rounded-xl mb-4" />
    <div class="w-full h-10 bg-gray-300 animate-pulse rounded" />
  </div>
</template>

<script>
export default {
  name: 'IncludeInputLoader',
};
</script>

<style scoped></style>
