var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "card-form-empty",
        [
          _c("template", { slot: "title" }, [_vm._v("Anomalous Distribution")]),
          _c("template", { staticClass: "flex", slot: "form" }, [
            _c("div", { staticClass: "flex flex-col w-full lg:flex-row" }, [
              _c(
                "div",
                { staticClass: "w-full lg:w-1/2 border-r border-gray-400" },
                [
                  _c("h3", { staticClass: "card-subtitle card-subtitle-l" }, [
                    _vm._v("Delivery (Impressions/clicks)"),
                  ]),
                  _c("div", { staticClass: "card-content" }, [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c(
                          "sun-label-group",
                          {
                            staticClass: "mt-2 w-full",
                            attrs: { text: "Banned User Agents" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex mt-3 mx-2" },
                              [
                                _c("sun-toggle", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    name: "banned-apps",
                                    disabled: _vm.isDisabled,
                                  },
                                  on: { change: _vm.onUpdateFraud },
                                  model: {
                                    value:
                                      _vm.fraud.controlUserAgents
                                        .bannedUserAgents,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.fraud.controlUserAgents,
                                        "bannedUserAgents",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "fraud.controlUserAgents.bannedUserAgents",
                                  },
                                }),
                                _c("p", { staticClass: "group-description" }, [
                                  _vm._v(_vm._s(_vm.userAgentsText("banned"))),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex mt-6" },
                      [
                        _c(
                          "sun-label-group",
                          {
                            staticClass: "mt-2 w-full",
                            attrs: { text: "Suspicious User Agents" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex mt-3 mx-2" },
                              [
                                _c("sun-toggle", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    name: "banned-apps",
                                    disabled: _vm.isDisabled,
                                  },
                                  on: { change: _vm.onUpdateFraud },
                                  model: {
                                    value:
                                      _vm.fraud.controlUserAgents
                                        .suspiciousUserAgents,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.fraud.controlUserAgents,
                                        "suspiciousUserAgents",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "fraud.controlUserAgents.suspiciousUserAgents",
                                  },
                                }),
                                _c("p", { staticClass: "group-description" }, [
                                  _vm._v(
                                    _vm._s(_vm.userAgentsText("suspicious"))
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex mt-6" },
                      [
                        _c(
                          "sun-label-group",
                          {
                            staticClass: "mt-2 w-full",
                            attrs: { text: "Wrong Language" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex mt-3 mx-2 items-center" },
                              [
                                _c("sun-toggle", {
                                  staticClass: "mr-2",
                                  attrs: { disabled: _vm.isDisabled },
                                  on: { change: _vm.onUpdateFraud },
                                  model: {
                                    value:
                                      _vm.fraud.controlLanguage
                                        .blockWrongLanguage,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.fraud.controlLanguage,
                                        "blockWrongLanguage",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "fraud.controlLanguage.blockWrongLanguage",
                                  },
                                }),
                                _c("p", { staticClass: "group-description" }, [
                                  _vm._v(_vm._s(_vm.wrongLanguageText)),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c("div", { staticClass: "w-full lg:w-1/2" }, [
                _c("h3", { staticClass: "card-subtitle card-subtitle-r" }, [
                  _vm._v("Adserver Core (Leads/PostEvents)"),
                ]),
                _c("div", { staticClass: "card-content" }, [
                  _c(
                    "div",
                    { staticClass: "flex flex-col" },
                    [
                      _c(
                        "sun-label-group",
                        {
                          staticClass: "mt-2 w-full",
                          attrs: {
                            text: "Retain Leads from Banned User Agents",
                          },
                        },
                        [
                          _c("sun-select", {
                            attrs: {
                              options: _vm.percentageValues,
                              "track-by": "id",
                              label: "name",
                              "close-on-select": "",
                              disabled: _vm.isDisabled,
                            },
                            on: { change: _vm.onUpdateFraud },
                            model: {
                              value:
                                _vm.fraud.controlUserAgents
                                  .bannedUserAgentsPercentage,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fraud.controlUserAgents,
                                  "bannedUserAgentsPercentage",
                                  $$v
                                )
                              },
                              expression:
                                "fraud.controlUserAgents.bannedUserAgentsPercentage",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.fraud.controlUserAgents.bannedUserAgentsPercentage
                        ? _c("p", { staticClass: "group-description" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.userAgentsPercentageText("banned")) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex mt-6 flex-col" },
                    [
                      _c(
                        "sun-label-group",
                        {
                          staticClass: "mt-2 w-full",
                          attrs: {
                            text: "Retain Leads from Suspicious User Agents",
                          },
                        },
                        [
                          _c("sun-select", {
                            attrs: {
                              options: _vm.percentageValues,
                              "track-by": "id",
                              label: "name",
                              "close-on-select": "",
                              disabled: _vm.isDisabled,
                            },
                            on: { change: _vm.onUpdateFraud },
                            model: {
                              value:
                                _vm.fraud.controlUserAgents
                                  .suspiciousUserAgentsPercentage,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fraud.controlUserAgents,
                                  "suspiciousUserAgentsPercentage",
                                  $$v
                                )
                              },
                              expression:
                                "fraud.controlUserAgents.suspiciousUserAgentsPercentage",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.fraud.controlUserAgents.suspiciousUserAgentsPercentage
                        ? _c("p", { staticClass: "group-description" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.userAgentsPercentageText("suspicious")
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }