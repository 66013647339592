<template>
  <div>
    <card-form-empty>
      <template slot="title">In App Control</template>
      <template slot="form" class="flex">
        <div class="flex flex-col w-full lg:flex-row">
          <div class="w-full lg:w-1/2 border-r border-gray-400">
            <h3 class="card-subtitle card-subtitle-l">Delivery (Impressions/clicks)</h3>
            <div class="card-content">
              <div class="flex">
                <sun-label-group class="mt-2 w-full" text="Banned Apps">
                  <div class="flex mt-3 mx-2">
                    <sun-toggle
                      v-model="fraud.controlApps.bannedApps"
                      class="mr-2"
                      name="banned-apps"
                      :disabled="isDisabled"
                      @change="onUpdateFraud"
                    />
                    <p class="group-description">{{ appControlText('banned') }}</p>
                  </div>
                </sun-label-group>
              </div>
              <div class="flex mt-6">
                <sun-label-group class="mt-2 w-full" text="Suspicious Apps">
                  <div class="flex mt-3 mx-2">
                    <sun-toggle
                      v-model="fraud.controlApps.suspiciousApps"
                      class="mr-2"
                      name="banned-apps"
                      :disabled="isDisabled"
                      @change="onUpdateFraud"
                    />
                    <p class="group-description">{{ appControlText('suspicious') }}</p>
                  </div>
                </sun-label-group>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-1/2">
            <h3 class="card-subtitle card-subtitle-r">Adserver Core (Leads/PostEvents)</h3>
            <div class="card-content">
              <div class="flex flex-col">
                <sun-label-group class="mt-2 w-full" text="Banned Apps">
                  <sun-select
                    v-model="fraud.controlApps.bannedAppsPercentage"
                    :options="percentageValues"
                    track-by="id"
                    label="name"
                    close-on-select
                    :disabled="isDisabled"
                    @change="onUpdateFraud"
                  />
                </sun-label-group>
                <p v-if="fraud.controlApps.bannedAppsPercentage" class="group-description">
                  {{ appControlPercentageText('banned') }}
                </p>
              </div>
              <div class="flex mt-6 flex-col">
                <sun-label-group class="mt-2 w-full" text="Suspicious Apps">
                  <sun-select
                    v-model="fraud.controlApps.suspiciousAppsPercentage"
                    :options="percentageValues"
                    track-by="id"
                    label="name"
                    close-on-select
                    :disabled="isDisabled"
                    @change="onUpdateFraud"
                  />
                </sun-label-group>
                <p v-if="fraud.controlApps.suspiciousAppsPercentage" class="group-description">
                  {{ appControlPercentageText('suspicious') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </card-form-empty>
  </div>
</template>
<script>
import { fraudFormMixin } from '@/mixins/modules/ecommerce/fraudFormMixin';
import { deepClone } from '@/utils/deepClone';
import CardFormEmpty from '@/components/atoms/CardFormEmpty';

export default {
  name: 'AppControl',
  components: { CardFormEmpty },
  mixins: [fraudFormMixin],
  props: {
    baseFraud: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fraud: null,
    };
  },
  watch: {
    baseFraud: {
      deep: true,
      handler(updatedFraud) {
        this.fraud = deepClone(updatedFraud);
      },
    },
  },
  created() {
    this.fraud = deepClone(this.baseFraud);
  },
};
</script>
<style scoped>
.group-description {
  @apply ml-2 pt-1 text-sm italic;
}
.card-subtitle-r {
  @apply rounded-tr-lg;
}
.card-subtitle-l {
  @apply rounded-tl-lg;
}
.card-subtitle {
  @apply bg-gray-200 px-6 border-b border-gray-400 flex items-center font-bold text-sm;
  height: 4rem;
}
.card-content {
  @apply flex flex-col p-6;
  height: calc(100% - 4rem);
}
</style>
