import { mapActions } from 'vuex';
import { PROXY_USAGE_TYPE_OPTIONS } from '@/entities/ecommerce/fraud/ProxyUsageType';
import { IP_MISSMATCH_OPTIONS } from '@/entities/ecommerce/fraud/IpMissMatch';
import { getASN } from '@/services/modules/ecommerce/offer/fraud/getASN';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import { PERCENTAGE_VALUES } from '@/model/modules/ecommerce/offer/fraud/PercentageOptions';

export const fraudFormMixin = {
  provide() {
    return {
      services: {
        asnFilter: this.getASN,
        sourcesFilter: this.getSources,
      },
    };
  },
  data() {
    return {
      ipUsageTypes: [
        { name: 'None', id: 'NONE' },
        { name: 'Only ISP, MOB, ISP/MOB', id: 'ONLY_ISP_MOB' },
        { name: 'All but DCH, SES, RSV', id: 'ALL_EXCEPT_DCH_SES_RSV' },
      ],
      proxyUsageTypeOptionsList: [
        PROXY_USAGE_TYPE_OPTIONS.NONE,
        PROXY_USAGE_TYPE_OPTIONS.ALL_EXCEPT_VPN_TOR_DCH_SES_RES,
        PROXY_USAGE_TYPE_OPTIONS.NOT_PROXIES,
      ],
      ipMissMatchOptionsList: [IP_MISSMATCH_OPTIONS.NONE, IP_MISSMATCH_OPTIONS.COUNTRY],
      sourcesList: [
        { id: 'Facebook', name: 'Facebook' },
        { id: 'Instagram', name: 'Instagram' },
        { id: 'Twitter', name: 'Twitter' },
        { id: 'Snapchat', name: 'Snapchat' },
        { id: 'Pinterest', name: 'Pinterest' },
        { id: 'Google', name: 'Google' },
        { id: 'Youtube', name: 'Youtube' },
        { id: 'Tiktok', name: 'Tiktok' },
      ],
      percentageValues: PERCENTAGE_VALUES,
    };
  },
  computed: {
    retainConversionsRepeatedText() {
      if (!this.fraud.ipControl.repeatedIps) {
        return '(no max IP frequency set)';
      }
      return `(${this.fraud.ipControl.maxLeadsPerIP} daily leads allowed per IP.)`;
    },
    ipMissMatchText() {
      if (this.fraud.ipMissMatch.value.id === IP_MISSMATCH_OPTIONS.NONE.id) return 'No control applied.';
      if (this.fraud.ipMissMatch.value.id === IP_MISSMATCH_OPTIONS.COUNTRY.id)
        return 'Different country IP, click vs conversion..';
      return '';
    },
    blockRepeatedIpsText() {
      if (this.fraud.ipControl.blockRepeatedIps) return 'Block traffic from Reated IPs.';
      return 'No control applied for clicks.';
    },
    wrongLanguageText() {
      if (this.fraud.controlLanguage.blockWrongLanguage) return 'Block traffic from wrong and empty languages.';
      return 'No control applied for clicks.';
    },
    uniqueLeadsText() {
      if (this.fraud.uniqueConversion) return 'Unique conversion (this selector changes the offer configuration).';
      return 'No control applied (this selector changes the offer configuration).';
    },
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    usageTypeDescription(type) {
      if (this.fraud.usageType[type].id === this.ipUsageTypes[0].id) return 'No control applied.';
      if (this.fraud.usageType[type].id === this.ipUsageTypes[1].id) return 'Only let enter these usage types.';
      if (this.fraud.usageType[type].id === this.ipUsageTypes[2].id) return 'Only block these usage types.';
      return '';
    },
    appControlText(type) {
      if (this.fraud.controlApps[`${type}Apps`]) return `Block traffic from ${type} Apps.`;
      return 'No control applied for clicks.';
    },
    appControlPercentageText(type) {
      if (this.fraud.controlApps[`${type}AppsPercentage`].id === 0) return 'No retention applied.';
      return `Retain ${this.fraud.controlApps[`${type}AppsPercentage`].name} of Conversions`;
    },
    changeAsnIncludes(value) {
      this.fraud.asn.asnFilter = value;
      this.onUpdateFraud();
    },
    userAgentsText(type) {
      if (this.fraud.controlUserAgents[`${type}UserAgents`]) return `Block traffic from ${type} user agents.`;
      return 'No control applied for clicks.';
    },
    userAgentsPercentageText(type) {
      if (this.fraud.controlUserAgents[`${type}UserAgentsPercentage`].id === 0) return 'No retention applied.';
      return `Retain ${this.fraud.controlUserAgents[`${type}UserAgentsPercentage`].name} of Conversions`;
    },
    changeRefererIncludes(value) {
      this.fraud.referer.sourcesFilter = value;
      this.onUpdateFraud();
    },
    changeIpControl() {
      this.fraud.ipControl.blockRepeatedIps = false;
      this.fraud.ipControl.maxLeadsPerIP = 0;
      this.onUpdateFraud();
    },
    onUpdateFraud() {
      this.$emit('update', this.fraud);
    },
    async getASN(searchTerm, pageNumber, pageSize) {
      const params = new QueryParamsBuilder(pageNumber, pageSize);
      params.addFilter('organizationOrNumber', searchTerm);
      try {
        return await getASN(params);
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.createToast(Toast.error('Getting ASN', error.message));
        }
      }
    },
    getSources() {
      return {
        data: this.sourcesList,
      };
    },
  },
};
