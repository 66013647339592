import { render, staticRenderFns } from "./IncludeInput.vue?vue&type=template&id=2dbddf3a&"
import script from "./IncludeInput.vue?vue&type=script&lang=js&"
export * from "./IncludeInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/D6nERZR5/0/sunmedia/asterix/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2dbddf3a')) {
      api.createRecord('2dbddf3a', component.options)
    } else {
      api.reload('2dbddf3a', component.options)
    }
    module.hot.accept("./IncludeInput.vue?vue&type=template&id=2dbddf3a&", function () {
      api.rerender('2dbddf3a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/shared/includes/IncludeInput.vue"
export default component.exports