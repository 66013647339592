var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "card-form",
        [
          _c("template", { slot: "title" }, [_vm._v("Referer")]),
          _c(
            "template",
            { slot: "form" },
            [
              _c("form-row", {
                scopedSlots: _vm._u([
                  {
                    key: "left",
                    fn: function () {
                      return [
                        _c("sun-label-group", [
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c("sun-toggle", {
                                staticClass: "mr-2",
                                attrs: {
                                  name: "only-traffic-with-IDFA-toggle",
                                  disabled: _vm.isDisabled,
                                },
                                on: { change: _vm.onUpdateFraud },
                                model: {
                                  value: _vm.fraud.referer.traficIDFA,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.fraud.referer,
                                      "traficIDFA",
                                      $$v
                                    )
                                  },
                                  expression: "fraud.referer.traficIDFA",
                                },
                              }),
                              _c("p", [_vm._v("Only traffic with IDFA/GAID")]),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "right",
                    fn: function () {
                      return [
                        _c(
                          "sun-label-group",
                          [
                            _c("sun-toggle", {
                              staticClass: "mr-2",
                              attrs: {
                                name: "only-traffic-with-referer-toggle",
                                disabled: _vm.isDisabled,
                              },
                              on: { change: _vm.onUpdateFraud },
                              model: {
                                value: _vm.fraud.referer.traficPublisher,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.fraud.referer,
                                    "traficPublisher",
                                    $$v
                                  )
                                },
                                expression: "fraud.referer.traficPublisher",
                              },
                            }),
                            _c("p", [_vm._v("Only traffic with Referer")]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("form-row", {
                scopedSlots: _vm._u([
                  {
                    key: "left",
                    fn: function () {
                      return [
                        _c("sun-label-group", [
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c("sun-toggle", {
                                staticClass: "mr-2",
                                attrs: {
                                  name: "only-traffic-with-domain-toggle",
                                  disabled: _vm.isDisabled,
                                },
                                on: { change: _vm.onUpdateFraud },
                                model: {
                                  value: _vm.fraud.referer.traficDomainBundle,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.fraud.referer,
                                      "traficDomainBundle",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "fraud.referer.traficDomainBundle",
                                },
                              }),
                              _c("p", [
                                _vm._v("Only traffic with domain/bundle"),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "right",
                    fn: function () {
                      return [
                        _c(
                          "sun-label-group",
                          [
                            _c("sun-toggle", {
                              staticClass: "mr-2",
                              attrs: {
                                name: "block-traffic-toggle",
                                disabled: _vm.isDisabled,
                              },
                              on: { change: _vm.onUpdateFraud },
                              model: {
                                value: _vm.fraud.referer.blockTraficApp,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.fraud.referer,
                                    "blockTraficApp",
                                    $$v
                                  )
                                },
                                expression: "fraud.referer.blockTraficApp",
                              },
                            }),
                            _c("p", [_vm._v("Block inapp traffic")]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("form-row", [
                _c(
                  "div",
                  { staticClass: "w-full lg:pr-4" },
                  [
                    _c("include-input", {
                      attrs: {
                        "include-id": "sourcesFilter",
                        title: "Sources",
                        disabled: _vm.isDisabled,
                      },
                      on: { input: _vm.changeRefererIncludes },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function () {
                            return [_vm._v(" + add sources include ")]
                          },
                          proxy: true,
                        },
                        {
                          key: "value-text",
                          fn: function ({ item }) {
                            return [_vm._v(_vm._s(item.name))]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.fraud.referer.sourcesFilter,
                        callback: function ($$v) {
                          _vm.$set(_vm.fraud.referer, "sourcesFilter", $$v)
                        },
                        expression: "fraud.referer.sourcesFilter",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }