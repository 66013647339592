import { api } from '..';
import { ASN_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';

export const getResources = () => ({
  [ASN_RESOURCE]: undefined,
});

/**
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data:ASN[], meta:EndpointMeta}>}
 */
export async function getASN(params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources());
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
