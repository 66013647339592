<template>
  <div>
    <card-form-empty>
      <template slot="title">IP Control</template>
      <template slot="form" class="flex">
        <div class="flex flex-col w-full lg:flex-row">
          <div class="w-full lg:w-1/2 border-r border-gray-400">
            <h3 class="card-subtitle card-subtitle-l">Delivery (Impressions/clicks)</h3>
            <div class="card-content">
              <div v-if="fraud.ipControl.repeatedIps" class="flex w-full">
                <sun-label-group class="w-full mt-2" text="Block Conversions for repeated IPs">
                  <div class="flex mt-3 mx-2">
                    <sun-toggle
                      v-model="fraud.ipControl.blockRepeatedIps"
                      class="mr-2"
                      name="repetead-ips-toggle"
                      :disabled="isDisabled"
                      @change="onUpdateFraud"
                    />
                    <p class="group-description">{{ blockRepeatedIpsText }}</p>
                  </div>
                </sun-label-group>
              </div>
              <div v-else class="w-full h-0 lg:h-16"></div>
              <div class="flex flex-col w-full mt-6">
                <sun-label-group class="mt-2 w-full" text="Usage type IP">
                  <sun-select
                    v-model="fraud.usageType.click"
                    :options="ipUsageTypes"
                    track-by="id"
                    label="name"
                    required="required"
                    text-error="This field is required."
                    close-on-select
                    :disabled="isDisabled"
                    @change="onUpdateFraud"
                  />
                </sun-label-group>
                <p v-if="fraud.usageType.click" class="group-description">{{ usageTypeDescription('click') }}</p>
              </div>
              <div class="flex w-full mt-6">
                <include-input
                  v-model="fraud.asn.asnFilter"
                  class="w-full"
                  allow-exclude
                  include-id="asnFilter"
                  title="ASN"
                  :paginated="true"
                  :disabled="isDisabled"
                  @input="changeAsnIncludes"
                >
                  <template #default> + add ASN include </template>
                  <template #value-text="{ item }">{{ item.name }}</template>
                </include-input>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-1/2">
            <h3 class="card-subtitle card-subtitle-r">Adserver Core (Leads/PostEvents)</h3>
            <div class="card-content">
              <div class="flex">
                <sun-label-group class="mt-2 w-1/2 pr-5" text="Retain Conversions for repeated IPs">
                  <div class="flex mt-3 mx-2">
                    <sun-toggle
                      v-model="fraud.ipControl.repeatedIps"
                      class="mr-2"
                      name="repetead-ips-toggle"
                      :disabled="isDisabled"
                      @change="changeIpControl"
                    />
                    <p class="group-description">{{ retainConversionsRepeatedText }}</p>
                  </div>
                </sun-label-group>
                <sun-label-group
                  v-if="!!fraud.ipControl.repeatedIps"
                  class="mt-2 w-1/2"
                  text="Max leads per Day per IP"
                >
                  <asterix-input
                    id="max-leads"
                    ref="ips"
                    v-model="fraud.ipControl.maxLeadsPerIP"
                    type="number"
                    name="max-leads"
                    validate-on-blur
                    :min="0"
                    :max="10"
                    :disabled="!fraud.ipControl.repeatedIps || isDisabled"
                    @change="onUpdateFraud"
                  />
                </sun-label-group>
              </div>
              <div class="flex mt-6">
                <div class="flex flex-col w-full">
                  <sun-label-group class="mt-2 w-full pr-5" text="Usage Type Click IP">
                    <sun-select
                      v-model="fraud.usageType.lead_click"
                      :options="ipUsageTypes"
                      track-by="id"
                      label="name"
                      required="required"
                      text-error="This field is required."
                      close-on-select
                      :disabled="isDisabled"
                      @change="onUpdateFraud"
                    />
                  </sun-label-group>
                  <p v-if="fraud.usageType.lead_click" class="group-description">
                    {{ usageTypeDescription('lead_click') }}
                  </p>
                </div>
                <div class="flex flex-col w-full">
                  <sun-label-group class="mt-2 w-full" text="Usage Type Notification IP">
                    <sun-select
                      v-model="fraud.usageType.lead_conversion"
                      :options="ipUsageTypes"
                      track-by="id"
                      label="name"
                      required="required"
                      text-error="This field is required."
                      close-on-select
                      :disabled="isDisabled"
                      @change="onUpdateFraud"
                    />
                  </sun-label-group>
                  <p v-if="fraud.usageType.lead_conversion" class="group-description">
                    {{ usageTypeDescription('lead_conversion') }}
                  </p>
                </div>
              </div>
              <div class="flex mt-6">
                <sun-label-group class="mt-2 w-full pr-5" text="Secondary IP Click">
                  <sun-select
                    v-model="fraud.proxyUsageType.leadClick"
                    :options="proxyUsageTypeOptionsList"
                    track-by="id"
                    label="name"
                    required="required"
                    text-error="This field is required."
                    close-on-select
                    :disabled="isDisabled"
                    @change="onUpdateFraud"
                  />
                </sun-label-group>
                <sun-label-group class="mt-2 w-full" text="Secondary IP Notification">
                  <sun-select
                    v-model="fraud.proxyUsageType.leadConversion"
                    :options="proxyUsageTypeOptionsList"
                    track-by="id"
                    label="name"
                    required="required"
                    text-error="This field is required."
                    close-on-select
                    :disabled="isDisabled"
                    @change="onUpdateFraud"
                  />
                </sun-label-group>
              </div>
              <div class="flex flex-col mt-6">
                <sun-label-group class="mt-2 w-full" text="IP Missmatch">
                  <sun-select
                    v-model="fraud.ipMissMatch.value"
                    :options="ipMissMatchOptionsList"
                    track-by="id"
                    label="name"
                    text-error="This field is required."
                    close-on-select
                    :disabled="isDisabled"
                    @change="onUpdateFraud"
                  />
                </sun-label-group>
                <p v-if="fraud.ipMissMatch.value" class="group-description">
                  {{ ipMissMatchText }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </card-form-empty>
  </div>
</template>
<script>
import { fraudFormMixin } from '@/mixins/modules/ecommerce/fraudFormMixin';
import { deepClone } from '@/utils/deepClone';
import CardFormEmpty from '@/components/atoms/CardFormEmpty';
import IncludeInput from '@/components/organisms/shared/includes/IncludeInput';
import AsterixInput from '@/components/atoms/AsterixInput';

export default {
  name: 'IpControl',
  components: { CardFormEmpty, IncludeInput, AsterixInput },
  mixins: [fraudFormMixin],
  props: {
    baseFraud: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fraud: null,
    };
  },
  watch: {
    baseFraud: {
      deep: true,
      handler(updatedFraud) {
        this.fraud = deepClone(updatedFraud);
      },
    },
  },
  created() {
    this.fraud = deepClone(this.baseFraud);
  },
};
</script>
<style scoped>
.group-description {
  @apply ml-2 pt-1 text-sm italic;
}
.card-subtitle-r {
  @apply rounded-tr-lg;
}
.card-subtitle-l {
  @apply rounded-tl-lg;
}
.card-subtitle {
  @apply bg-gray-200 px-6 border-b border-gray-400 flex items-center font-bold text-sm;
  height: 4rem;
}
.card-content {
  @apply flex flex-col p-6;
  height: calc(100% - 4rem);
}
</style>
