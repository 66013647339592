<template>
  <section v-if="value">
    <include-input-loader v-if="isLoading || !includeSelected" />

    <div v-else>
      <header class="flex justify-end items-center">
        <sun-button
          variant="pill"
          class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800 relative"
          color="gray"
          :disabled="disabled"
          @click="onNewInclude"
        >
          <slot></slot>
        </sun-button>
      </header>

      <include-item-list
        v-if="showInclude"
        :allow-exclude="allowExclude"
        class="mt-4"
        :include="includeSelected"
        :filter-name="filterName"
        :read-only="disabled"
        @edit="onNewInclude"
        @remove="removeItem"
      >
        <template #item="{ item, type }">
          <slot name="value" :item="{ item, type }"></slot>
        </template>
        <template #item-text="{ item }">
          <slot name="value-text" :item="item"> {{ item }} </slot>
        </template>
      </include-item-list>

      <asterix-no-data
        v-else
        title="No includes yet"
        subtitle="Use the add button above to create your first include  "
        class="text-center"
      />
    </div>

    <include-modal
      v-if="openModal"
      :base-include="includeSelected"
      :title="title"
      :paginated="paginated"
      :allow-exclude="allowExclude"
      @cancel="onNewIncludeCancel"
      @update="onNewIncludeAdded"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import AsterixNoData from '@/components/organisms/shared/AsterixNoData';
import IncludeItem from '@/model/shared/IncludeItem';
import IncludeInputLoader from '@/components/atoms/Loaders/IncludeInputLoader';
import IncludeModal from '@/components/organisms/shared/includes/IncludeModal';
import IncludeItemList from '@/components/organisms/shared/includes/IncludeItemList';

export default {
  name: 'IncludeInput',
  components: {
    IncludeInputLoader,
    IncludeModal,
    AsterixNoData,
    IncludeItemList,
  },
  props: {
    value: {
      /** @type IncludeItem */
      type: Object,
      default: () => null,
    },
    includeId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    filterName: {
      type: Function,
      default: value => value,
    },
    paginated: {
      type: Boolean,
      default: false,
    },
    allowExclude: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
    includeSelected: null,
    openModal: false,
  }),
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
    }),
    showInclude() {
      return this.includeSelected?.type !== IncludeItem.VALID_TYPES.NONE;
    },
  },
  watch: {
    value(newVal) {
      this.updateIncludes(newVal);
    },
  },
  created() {
    this.isLoading = true;
    this.updateIncludes(this.value);
    this.isLoading = false;
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    updateIncludes(newInclude) {
      this.includeSelected = IncludeItem.deepClone(newInclude);
    },
    onNewInclude() {
      this.openModal = true;
    },
    onNewIncludeCancel() {
      this.openModal = false;
    },
    onNewIncludeAdded(include) {
      this.openModal = false;
      const update = this.includeSelected;

      this.$set(update, 'value', include.value);
      this.$set(update, 'type', include.type);

      this.$emit('input', this.includeSelected);
    },
    removeItem(itemId) {
      this.includeSelected.value = this.includeSelected.value.filter(item => item.id !== itemId);
      const isIncludeSelected = !!this.includeSelected.value.length;
      this.$set(this.includeSelected, 'selected', isIncludeSelected);
      this.$set(
        this.includeSelected,
        'type',
        isIncludeSelected ? this.includeSelected.type : IncludeItem.VALID_TYPES.NONE
      );
      this.$emit('input', this.includeSelected);
    },
  },
};
</script>
