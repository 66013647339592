var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-form flex flex-col" }, [
    _c(
      "span",
      {
        staticClass:
          "bg-gray-400 font-bold leading-none ml-2 sm:ml-8 pl-4 pt-3 px-8 py-2 rounded-t-lg text-left w-10/12 sm:w-2/5",
      },
      [_vm._t("title")],
      2
    ),
    _c(
      "div",
      { staticClass: "border border-gray-400 rounded-lg shadow-lg" },
      [_vm._t("form")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }