var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value
    ? _c(
        "section",
        [
          _vm.isLoading || !_vm.includeSelected
            ? _c("include-input-loader")
            : _c(
                "div",
                [
                  _c(
                    "header",
                    { staticClass: "flex justify-end items-center" },
                    [
                      _c(
                        "sun-button",
                        {
                          staticClass:
                            "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800 relative",
                          attrs: {
                            variant: "pill",
                            color: "gray",
                            disabled: _vm.disabled,
                          },
                          on: { click: _vm.onNewInclude },
                        },
                        [_vm._t("default")],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm.showInclude
                    ? _c("include-item-list", {
                        staticClass: "mt-4",
                        attrs: {
                          "allow-exclude": _vm.allowExclude,
                          include: _vm.includeSelected,
                          "filter-name": _vm.filterName,
                          "read-only": _vm.disabled,
                        },
                        on: { edit: _vm.onNewInclude, remove: _vm.removeItem },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function ({ item, type }) {
                                return [
                                  _vm._t("value", null, {
                                    item: { item, type },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "item-text",
                              fn: function ({ item }) {
                                return [
                                  _vm._t(
                                    "value-text",
                                    function () {
                                      return [_vm._v(" " + _vm._s(item) + " ")]
                                    },
                                    { item: item }
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _c("asterix-no-data", {
                        staticClass: "text-center",
                        attrs: {
                          title: "No includes yet",
                          subtitle:
                            "Use the add button above to create your first include  ",
                        },
                      }),
                ],
                1
              ),
          _vm.openModal
            ? _c("include-modal", {
                attrs: {
                  "base-include": _vm.includeSelected,
                  title: _vm.title,
                  paginated: _vm.paginated,
                  "allow-exclude": _vm.allowExclude,
                },
                on: {
                  cancel: _vm.onNewIncludeCancel,
                  update: _vm.onNewIncludeAdded,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }